import React, { useEffect, useState, useRef  } from 'react';
import { useNavigate, generatePath, Link, BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import NavBarMain from '../NavBarMain';

function DailySummaryReport() {

  const apireq05042024 = process.env.REACT_APP_SERVER_API_URL;
        
  const navigate = useNavigate();
  const [UserMasterDataGlobal, setUserMasterDataGlobal] = useState([]);
  const [lastActivityTime, setLastActivityTime] = useState(Date.now());

  const usernameglobal = JSON.parse(localStorage.getItem('username'));
  const localstorage_id = JSON.parse(localStorage.getItem('localstorage_id'));

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(apireq05042024+`UserMaster/getdatabyusername?username=${usernameglobal}&localstorage_id=${localstorage_id}`);
        if(response.data == "logout") {
          navigatetologin();
        }
        setUserMasterDataGlobal(response.data.UserMasterDataGlobal);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    // Initial fetch if usernameglobal is available
    if (usernameglobal) {
      fetchData();
    }

    // Set up interval to fetch data every 5 minutes
    const intervalId = setInterval(fetchData, 5 * 60 * 1000); // 5 minutes in milliseconds

    // Cleanup function to clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [usernameglobal]); // Dependency array includes usernameglobal

  useEffect(() => {
    if (usernameglobal === null) {
      navigate('/LoginPage');
    } else {
      // Reset last activity time whenever there's user interaction
      const handleUserActivity = () => {
        setLastActivityTime(Date.now());
      };

      window.addEventListener('mousemove', handleUserActivity);
      window.addEventListener('keydown', handleUserActivity);
      window.addEventListener('touchstart', handleUserActivity); // For touch devices

      return () => {
        window.removeEventListener('mousemove', handleUserActivity);
        window.removeEventListener('keydown', handleUserActivity);
        window.removeEventListener('touchstart', handleUserActivity);
      };
    }
  }, [usernameglobal, navigate]);

  useEffect(() => {
    const sessionTimeout = setTimeout(() => {
      const currentTime = Date.now();
      const elapsedTime = currentTime - lastActivityTime;

      if (elapsedTime > 15 * 60 * 1000) { // Log out after 15 minutes of inactivity
        console.log('Logging out due to inactivity...');
        navigatetologin();
      } else {
        // If there's less than 15 minutes left, reset the timeout to avoid unnecessary checks
        sessionTimeout.refresh();
      }
    }, 15 * 60 * 1000); // Check for inactivity every 15 minutes (converted to milliseconds)

    return () => clearTimeout(sessionTimeout);
  }, [lastActivityTime, navigatetologin]);

  // console.log(UserMasterDataGlobal[0].usertype);
  async function navigatetologin() {
    const response = await axios.get(apireq05042024+`UserMaster/logout?username=${usernameglobal}`);
    localStorage.removeItem('username');
    localStorage.removeItem('localstorage_id');
    navigate('/LoginPage');
  }

    const [CustomerMasterData, setCustomerMasterData] = useState([]);

    const [errors, seterrors] = useState({});

    const handleInputChange = (e) => {
      const { name, value } = e.target;
      const updatedFormData = {
        ...formData,
        [name]: value,
      };

      setFormData(updatedFormData);
    };

    const [formData, setFormData] = useState({
      ofdate: '',
    });

    useEffect(() => {
      // Update the date in the formData when the component mounts
      setFormData((prevData) => ({
        ...prevData,
        ofdate : getCurrentDate(),
      }));
    }, []); 

    const getCurrentDate = () => {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, '0');
      const day = String(today.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    };

  async function viewonclick (event) {
    // function regeneraterecords(event) {
      event.preventDefault();     
      
      GetOutStandingReport();      
  }

    //These variables declared here to show Total of Qty and Amt at the last of table.
    var TotalcashRecd =0;
    var TotalbankRecd =0;    
    var TotalCramt =0;

  async function printContent (event) {
    event.preventDefault();

    GetOutStandingReport();
    
    const printWindow = window.open('', '_blank');

    var TotalcashRecdPrint = 0;
    var TotalbankRecdPrint = 0;
    var TotalCramtPrint = 0;

    printWindow.document.write(`
    <html>
    <head>
      <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-rbsA2VBKQhggwzxH7pPCaAqO46MgnOM80zW1RWuH61DGLwZJEdK2Kadq2F9CUG65" crossorigin="anonymous">
        <style>
          @media print {
            @page {
              size: A4;
              margin: 1cm;
            }
            .DailySummaryReport {
              width: 18cm;
              height: 29.7cm;
              margin: 0 auto;
            }
            .table {
              font-size: 12px;
              border-collapse: collapse;
            }
            .table th, .table td {
              border: 1px solid black !important;
              padding: 5px;
            }
          }
        </style>
    </head>
    <body class="DailySummaryReport">
    <div class="table-container col-md-12 ">
      <h1 style="color: red; font-size: 30px; font-weight: bold; margin-top: 5px; text-align: center;">Daily Summary Report</h1>
      
      <table class="table table-bordered mt-3"  style="border: 1px solid black">
        <thead>
          <tr>
            <th style="color: red;">Customer Name</th>
            <th style="color: red; text-align: right;">Cash Recd</th>
            <th style="color: red; text-align: right;">Bank Recd</th>
            <th style="color: red; text-align: right;">Total Recd</th>
          </tr>
          </thead>
          <tbody>
          ${CustomerMasterData.map((c, index) => {
            TotalcashRecdPrint += Number(c.cashRecd);
            TotalbankRecdPrint += Number(c.bankRecd);
            TotalCramtPrint += Number(c.totalAmount);
      
            return `
              <tr key=${index}>
                <td>${c.customer_name}</td>
                <td style="text-align: right;">${Number(c.cashRecd).toFixed(2)}</td>
                <td style="text-align: right;">${Number(c.bankRecd).toFixed(2)}</td>                
                <td style="text-align: right;">${Number(c.totalAmount).toFixed(2)}</td>
              </tr>
            `;
          }).join('')}
          <tfoot>
            <tr>
              <td style="font-size: 18px; font-weight: bolder;">Total</td>
              <td style="text-align: right; font-size: 18px; font-weight: bolder;">${Number(TotalcashRecdPrint).toFixed(2)}</td>
              <td style="text-align: right; font-size: 18px; font-weight: bolder;">${Number(TotalbankRecdPrint).toFixed(2)}</td>
              <td style="text-align: right; font-size: 18px; font-weight: bolder;">${Number(TotalCramtPrint).toFixed(2)}</td>
            </tr>
          </tfoot>      
        </tbody>
      </table>
    </div>
    </body>
    </html>
  `);
  
    // Automatically open the print dialog after the content is loaded
    printWindow.document.close();
    printWindow.focus(); // Bring the print window to the front
    printWindow.print(); // Open the print dialog
  };

  async function GetOutStandingReport(toshowcustomermasterdata = null) {

      var validationerrors = {};

      if(formData.ofdate !== '') {
      }else{
        validationerrors.ofdate = "Please select prefered to date from datepicker";
      }

      seterrors(validationerrors);

      if(Object.keys(validationerrors).length == 0) {

        var data = {
          ofdate: formData.ofdate || getCurrentDate(),
          Companyid: UserMasterDataGlobal?.[0]?.Companyid,
          userid: UserMasterDataGlobal?.[0]?._id,
          username: UserMasterDataGlobal?.[0]?.username,
        };

        setFormData({
          ...formData,
          ofdate: data.ofdate,
        });

        try {          
                   
          if(toshowcustomermasterdata !== "notshow") {
            var response  = "";

            response = await axios.post(process.env.REACT_APP_SERVER_API_URL+'CustomerTransactionMaster/GetDailySummaryReport/', data);
            setCustomerMasterData([]);     
            if (response.status === 200) { 
              // console.log(response.data);
                if(response.data.length >0) {
                  setCustomerMasterData(response.data);          
                }       
            } 
            
          }

        } catch {

        } 

      }  

  }

    return(
           
      <>
      <h1 style={{ color: 'white', backgroundColor: '#4229cb', padding: '5px', cursor: 'default' }} className="text-center"> Daily Summary Report</h1>
      <NavBarMain />
      <div className="container-fluid" style={{color: '#4229cb', fontWeight: '500' ,backgroundColor: 'rgb(255 255 255)'}} >
      <div className="row" styles={{backgroundColor: 'lightgreen'}}>
        {/* <div className="col-md-6 offset-md-3"> */}
          <form method='get'> 

            <div className="row mb-2">

              <div className="col-md-2" style={{width: '12%'}}>
                <label htmlFor="ofdate" className="form-label">Date</label>
                <input type="date" className="form-control" name="ofdate" id="id-ofdate" value={formData.ofdate} onChange={handleInputChange} required/>
                {errors.ofdate && <span style={{color: 'red', fontSize: '10px'}}>{errors.ofdate}</span>}
              </div>

              <div className="col-md-1">
                <button type="submit" className="btn btn-primary w-100" style={{marginTop: '32px'}} onClick={(event) => viewonclick(event)}>View</button>                
              </div>

              <div className="col-md-1">
                <button className="btn btn-primary w-100" style={{marginTop: '32px', backgroundColor: 'green', borderColor: 'green'}} onClick={printContent}>Print</button>
              </div>               
            </div> 
          </form>
      </div>


      <div className="row mt-2">
        <div className="col-md-12 table-responsive-xl">
        {/* below table-container code addded to keep table size fixed still its records increases */}
          <div className="table-container" style={{ height: '350px', overflow: 'auto', borderWidth:'0px' }}>
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th style={{backgroundColor:'#726200', color:"white", opacity:'0.8', width: '5%', textAlign: 'left'}}>Customer Name</th>
                  <th style={{backgroundColor:'#726200', color:"white", opacity:'0.8', width: '5%'}}>Cash Recd</th>
                  <th style={{backgroundColor:'#726200', color:"white", opacity:'0.8', width: '5%'}}>Bank Recd</th>
                  <th style={{backgroundColor:'#726200', color:"white", opacity:'0.8', width: '5%'}}>Total Recd</th>
                </tr>
              </thead>
              <tbody>
              {CustomerMasterData.map((c, index) => {
                
                TotalcashRecd = Number(TotalcashRecd) + Number(c.cashRecd);
                TotalbankRecd = Number(TotalbankRecd) + Number(c.bankRecd);
                TotalCramt = Number(TotalCramt) + Number(c.totalAmount);

                return (
                  <tr key={c.customer_mp_id}>
                    <td>{c.customer_name}</td>
                    <td style={{textAlign: 'right'}}>{Number(c.cashRecd).toFixed(2)}</td>
                    <td style={{textAlign: 'right'}}>{Number(c.bankRecd).toFixed(2)}</td>
                    <td style={{textAlign: 'right'}}>{Number(c.totalAmount).toFixed(2)}</td>
                  </tr>
                );
              })}              

              </tbody>

              <tfoot>
                <tr>
                  <td style={{fontSize: '18px', fontWeight: 'bolder'}}>Total</td>
                  <td style={{textAlign: 'right', fontSize: '18px', fontWeight: 'bolder'}}>{Number(TotalcashRecd).toFixed(2)}</td>
                  <td style={{textAlign: 'right', fontSize: '18px', fontWeight: 'bolder'}}>{Number(TotalbankRecd).toFixed(2)}</td>
                  <td style={{textAlign: 'right', fontSize: '18px', fontWeight: 'bolder'}}>{Number(TotalCramt).toFixed(2)}</td>
                </tr>
              </tfoot>

            </table>
          </div>

        </div>
      </div>

    </div>
    </>
    );

}

export default DailySummaryReport;