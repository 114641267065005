import React, { useEffect, useState, useRef  } from 'react';
import { useNavigate, generatePath, Link, BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import NavBarMain from '../NavBarMain';

function CrossTypeReport() {

    const apireq05042024 = process.env.REACT_APP_SERVER_API_URL;
        
    const navigate = useNavigate();
    const [UserMasterDataGlobal, setUserMasterDataGlobal] = useState([]);
    const [lastActivityTime, setLastActivityTime] = useState(Date.now());

    const usernameglobal = JSON.parse(localStorage.getItem('username'));
    const localstorage_id = JSON.parse(localStorage.getItem('localstorage_id'));

    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await axios.get(apireq05042024+`UserMaster/getdatabyusername?username=${usernameglobal}&localstorage_id=${localstorage_id}`);
          if(response.data == "logout") {
            navigatetologin();
          }
          setUserMasterDataGlobal(response.data.UserMasterDataGlobal);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      // Initial fetch if usernameglobal is available
      if (usernameglobal) {
        fetchData();
      }

      // Set up interval to fetch data every 5 minutes
      const intervalId = setInterval(fetchData, 5 * 60 * 1000); // 5 minutes in milliseconds

      // Cleanup function to clear the interval when the component unmounts
      return () => clearInterval(intervalId);
    }, [usernameglobal]); // Dependency array includes usernameglobal

    useEffect(() => {
      if (usernameglobal === null) {
        navigate('/LoginPage');
      } else {
        // Reset last activity time whenever there's user interaction
        const handleUserActivity = () => {
          setLastActivityTime(Date.now());
        };

        window.addEventListener('mousemove', handleUserActivity);
        window.addEventListener('keydown', handleUserActivity);
        window.addEventListener('touchstart', handleUserActivity); // For touch devices

        return () => {
          window.removeEventListener('mousemove', handleUserActivity);
          window.removeEventListener('keydown', handleUserActivity);
          window.removeEventListener('touchstart', handleUserActivity);
        };
      }
    }, [usernameglobal, navigate]);

    useEffect(() => {
      const sessionTimeout = setTimeout(() => {
        const currentTime = Date.now();
        const elapsedTime = currentTime - lastActivityTime;

        if (elapsedTime > 15 * 60 * 1000) { // Log out after 15 minutes of inactivity
          console.log('Logging out due to inactivity...');
          navigatetologin();
        } else {
          // If there's less than 15 minutes left, reset the timeout to avoid unnecessary checks
          sessionTimeout.refresh();
        }
      }, 15 * 60 * 1000); // Check for inactivity every 15 minutes (converted to milliseconds)

      return () => clearTimeout(sessionTimeout);
    }, [lastActivityTime, navigatetologin]);

    // console.log(UserMasterDataGlobal[0].usertype);
    async function navigatetologin() {
      const response = await axios.get(apireq05042024+`UserMaster/logout?username=${usernameglobal}`);
      localStorage.removeItem('username');
      localStorage.removeItem('localstorage_id');
      navigate('/LoginPage');
    }

    

    const [CustomerMasterData, setCustomerMasterData] = useState([]);

    const [errors, seterrors] = useState({});
    
    const handleInputChange = (e) => {
      const { name, value } = e.target;
      const updatedFormData = {
        ...formData,
        [name]: value,
      };

      setFormData(updatedFormData);
    };

    const [formData, setFormData] = useState({
      tilldate: '',
    });

    useEffect(() => {
      // Update the date in the formData when the component mounts
      setFormData((prevData) => ({
        ...prevData,
        tilldate : getCurrentDate(),
      }));
    }, []); 

    const getCurrentDate = () => {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, '0');
      const day = String(today.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    };

  async function viewonclick (event) {
    // function regeneraterecords(event) {
      event.preventDefault();     
      
      GetCrossTypeReport();
      
  }

  async function printContent(event) {
    event.preventDefault();
  
    GetCrossTypeReport();
    // const columns = [...new Set(ClothMasterData.map(item => item.cloth_type))];

    const printWindow = window.open('', '_blank');

    const clothHeaders = ClothMasterData.length > 0
    ? ClothMasterData.map(c => `
        <th style="color:black; width: 0.5%; text-align: right;">${c}</th>
      `).join('')
    : '<th style="color:black; width: 0.5%; text-align: right;">No Data</th>';

    const customerRows = CustomerMasterData.map(customer => {
      // const clothCells = ClothMasterData.map(cloth => `
      //   <td>${customer.cloth_counts[cloth.cloth_type] || 0}</td>
      // `).join('');    
      let total = 0;
      const clothCells = ClothMasterData.map(cloth => {
        const count = customer.cloth_counts[cloth] || 0;
        total += count; // Add count to total
        return `<td style="text-align: right;" key="${cloth}">${count}</td>`;
      }).join('') + `<td  style="text-align: right; key="total">${total}</td>`;

      
      return `
        <tr key="${customer.customer_name}">
          <td>${customer.customer_name}</td>
          ${clothCells}
        </tr>
      `;
    }).join('');

    const totalRowHTML = `
      <tr>
        <td style="font-weight: bolder; font-size: 18px">Total</td>
        ${ClothMasterData.map(cloth => {
          const total = CustomerMasterData.reduce((acc, customer) => {
            return acc + (customer.cloth_counts[cloth] || 0);
          }, 0);
          return `<td style="font-weight: bolder; font-size: 18px; text-align: right;">${total}</td>`;
        }).join('')}

        <td style="font-weight: bolder; font-size: 18px; text-align: right;">
          ${Object.values(clothTotals).reduce((acc, val) => acc + val, 0)}
        </td>

      </tr>
    `;
  
    // Assuming formData.tilldate contains a valid date string
    const tillDate = new Date(formData.tilldate); // Convert the date string to a Date object
    const formattedDate = tillDate.toLocaleDateString('en-GB'); // Format as dd/MM/yyyy
        
    printWindow.document.write(`
      <html>
      <head>
        <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-rbsA2VBKQhggwzxH7pPCaAqO46MgnOM80zW1RWuH61DGLwZJEdK2Kadq2F9CUG65" crossorigin="anonymous">
          <style>
            @media print {
              @page {
                size: A4;
                margin: 1cm;
              }
              .bodyCrossTypeReport {
                width: 18cm;
                height: 29.7cm;
                margin: 0 auto;
              }
              .table {
                font-size: 12px;
                border-collapse: collapse;
              }
              .table th, .table td {
                border: 1px solid black !important;
                padding: 5px;
              }
            }
          </style>
      </head>
      <body class="bodyCrossTypeReport">
      <div class="table-container col-md-12">
        <h1 style="color: red; font-size: 20px; font-weight: bold; margin-top: 5px; text-align: center;">${formattedDate}</h1> 
        <h1 style="color: red; font-size: 30px; font-weight: bold; margin-top: 5px; text-align: center;">Daily Inward (Cross-Tab Report)</h1>
        
        <table class="table table-bordered mt-3" style="border: 1px solid black">
          <thead>
            <tr>
              <th style="color:Black; opacity:0.8; width:1%;">Customer Name</th>
              ${clothHeaders}
              <th style="color:black; opacity:0.8; width:1%; text-align: right;">Total</th>
            </tr>
          </thead>
          <tbody>
            ${customerRows}            
            ${totalRowHTML}
          </tbody>
        </table>
      </div>
      </body>
      </html>
    `);

    // Automatically open the print dialog after the content is loaded
    printWindow.document.close();
    printWindow.focus(); // Bring the print window to the front
    printWindow.print(); // Open the print dialog
};

const [ClothMasterData, setClothMasterData] = useState([]);

async function GetCrossTypeReport(toshowcustomermasterdata = null) {
  var validationerrors = {};

  if (formData.tilldate === '') {
      validationerrors.tilldate = "Please select preferred to date from datepicker";
  }

  seterrors(validationerrors);

  if (Object.keys(validationerrors).length === 0) {
      const data = {
          tilldate: formData.tilldate || getCurrentDate(),
          Companyid: UserMasterDataGlobal?.[0]?.Companyid,
          userid: UserMasterDataGlobal?.[0]?._id,
          username: UserMasterDataGlobal?.[0]?.username,
      };

      setFormData({
          ...formData,
          tilldate: data.tilldate,
      });

      try {
          if (toshowcustomermasterdata !== "notshow") {
              const response = await axios.post(
                  `${process.env.REACT_APP_SERVER_API_URL}CustomerTransactionMaster/GetCrossTypeReport/`,
                  data
              );

              if (response.status === 200) {
                  const { reportData, clothArray } = response.data;

                  // Ensure data exists before updating state
                  if (reportData && clothArray) {
                      console.log(reportData);
                      console.log(clothArray);

                      setCustomerMasterData(reportData);
                      setClothMasterData(clothArray);
                  } else {
                      console.error("Response data is empty or invalid.");
                  }
              }
          }
      } catch (error) {
          console.error("Error fetching report:", error);
      }
  }
}


  // const [ClothMasterData, setClothMasterData] = useState([]);

  // useEffect(() => {
  //   // Fetch data from the specified URL
  //   axios.get(process.env.REACT_APP_SERVER_API_URL+'ClothMaster/show')  
  //       .then((response) => {
  //         setClothMasterData(response.data);
  //       })
  //       .catch((error) => console.error('Error fetching data:', error));
  // }, []);

  const [clothTotals, setClothTotals] = useState({});

  useEffect(() => {
    // Calculate totals for each cloth type
    const calculateTotals = () => {
      const totals = {};
      ClothMasterData.forEach(cloth => {
        let total = 0;
        CustomerMasterData.forEach(customer => {
          total += customer.cloth_counts[cloth] || 0;
        });
        totals[cloth] = total;
      });
      setClothTotals(totals);
    };

    calculateTotals();
  }, [CustomerMasterData]); // Recalculate totals when CustomerMasterData changes

    return(
           
      <>
      <h1 style={{ color: 'white', backgroundColor: '#4229cb', padding: '5px', cursor: 'default' }} className="text-center"> Daily Inward (Cross-Tab Report) </h1>
      <NavBarMain />
      <div className="container-fluid" style={{color: '#4229cb', fontWeight: '500' ,backgroundColor: 'rgb(255 255 255)'}} >
      <div className="row" styles={{backgroundColor: 'lightgreen'}}>
        {/* <div className="col-md-6 offset-md-3"> */}
          <form method='get'> 

            <div className="row mb-2">

              <div className="col-md-2 col-12" style={{width: '12%'}}>
                <label htmlFor="tilldate" className="form-label">Date</label>
                <input type="date" className="form-control" name="tilldate" id="id-tilldate" value={formData.tilldate} onChange={handleInputChange} required/>
                {errors.tilldate && <span style={{color: 'red', fontSize: '10px'}}>{errors.tilldate}</span>}
              </div>

              <div className="col-md-1">
                <button type="submit" className="btn btn-primary w-100" style={{marginTop: '32px'}} onClick={(event) => viewonclick(event)}>View</button>
              </div>

              <div className="col-md-1">
                <button className="btn btn-primary w-100" style={{marginTop: '32px', backgroundColor: 'green', borderColor: 'green'}} onClick={printContent}>Print</button>
              </div>

            </div> 
          </form>
      </div>

      <div className="row mt-2">
        <div className="col-md-12 table-responsive-xl">
        {/* below table-container code addded to keep table size fixed still its records increases */}
          <div className="table-container" style={{ height: '350px', overflow: 'auto', borderWidth:'0px' }}>
            <table className="table table-bordered">
              <thead>

                <tr>
                  <th style={{backgroundColor:'#726200', color:"white", opacity:'0.8', width: '5%'}}>Customer Name</th>
                  {!ClothMasterData || ClothMasterData.length === 0 ? null : 
                    ClothMasterData.map((c, index) => (
                      <th key={index} style={{ backgroundColor: '#726200', color: 'white', opacity: '0.8', width: '5%' }}>{c}</th>
                    ))
                  }
                  <th style={{backgroundColor:'#726200', color:"white", opacity:'0.8', width: '5%'}}>Total</th>
                </tr>                        

              </thead>
              <tbody>
              {CustomerMasterData.map(customer => {
                let total = 0; // Initialize total for each row
                console.log(customer);
                return (
                  
                  <tr key={customer.customer_name}>
                    <td>{customer.customer_name}</td>
                    {ClothMasterData.map(cloth => {
                      const count = customer.cloth_counts[cloth] || 0;
                      total += count; // Add count to total
                      return <td style={{textAlign: 'right'}} key={cloth}>{count}</td>;
                    })}
                    <td style={{textAlign: 'right'}} key="total">{total}</td>                    
                  </tr>
                );
              })}

              <tr>
                <td style={{fontWeight: 'bolder', fontSize: '18px'}}>Total</td>
                {ClothMasterData.map(cloth => (
                  <td style={{fontWeight: 'bolder', fontSize: '18px', textAlign: 'right'}} key={cloth}>{clothTotals[cloth]}</td>
                ))}    
                <td style={{fontWeight: 'bolder', fontSize: '18px', textAlign: 'right'}}>
                  {/* Calculate the total of all values in clothTotals */}
                  {Object.values(clothTotals).reduce((acc, val) => acc + val, 0)}
                </td>
              </tr>
            
              </tbody>
            </table>
          </div>

        </div>
      </div>

    </div>
    </>

    );

}

export default CrossTypeReport;