import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import '../css/Bill.css';

function Bill() {
  const { BillId } = useParams();
  const apireq05042024 = process.env.REACT_APP_SERVER_API_URL;
  const [data, setData] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apireq05042024}CustomerTransactionMaster/GetBillReport/${BillId}`);
        // console.log(response.data);
        setData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [BillId, apireq05042024]);

  useEffect(() => {
    // Open the print dialog after a delay to allow the content to load
    const timer = setTimeout(() => {
      window.print();
    }, 800);

    return () => clearTimeout(timer); // Cleanup timer
  }, [BillId, apireq05042024]);

  if (!data || !data.customerTransaction || !data.clothData) {
    return <p>Loading...</p>;
  }

  const { customerTransaction, clothData } = data;

  return (
    <div className="container mt-2">
      <div className="row justify-content-center">
        <div className="col-md-8">
          <div className="card" style={{ border: '1px solid black' }}>
            <div className="card-header">
              <h5 className="card-title" style={{ textAlign: 'center', fontSize: '24px' }}>Rajesh Power Laundry, Karad</h5>
            </div>
            <div className="card-body">
              <table className="table table-sm" style={{borderCollapse: 'collapse', border: 'none'}}>
                <tbody>
                  <tr style={{ border: 'none'}}>
                    <td style={{border: 'none'}}>Inward No. </td> <td style={{border: 'none'}}>{customerTransaction.tagno}</td>
                    <td style={{border: 'none'}}></td><td style={{border: 'none'}}></td>
                    <td style={{border: 'none', textAlign: 'right'}}>Inward Date:</td> <td style={{border: 'none', textAlign: 'right'}}>{new Date(customerTransaction.tranTimeStamp).toLocaleDateString('en-GB')}{" "}
                    {new Date(customerTransaction.tranTimeStamp).toLocaleTimeString('en-GB', {
                      hour: '2-digit',
                      minute: '2-digit',
                      hour12: true,
                    })}</td>
                  </tr>
                  <tr style={{ border: 'none'}}>
                    <td style={{border: 'none'}}>Status. </td> <td style={{border: 'none'}}>{customerTransaction.status}</td>
                    <td style={{border: 'none'}}></td><td style={{border: 'none'}}></td>
                    <td style={{border: 'none', textAlign: 'right'}}>Delivery Date:</td> <td style={{border: 'none', textAlign: 'right'}}>{new Date(customerTransaction.deliveryon).toLocaleDateString('en-GB')}{" "}
                    {(() => {
                      const dateObj = new Date(customerTransaction.deliveryon);
                      let hours = dateObj.getUTCHours();
                      const minutes = dateObj.getUTCMinutes().toString().padStart(2, '0'); // Ensure two digits for minutes
                      const amPm = hours >= 12 ? 'PM' : 'AM';  // Determine AM/PM
                      hours = hours % 12 || 12;  // Convert 24-hour time to 12-hour format, with 12 as noon/midnight
                      return `${hours}:${minutes} ${amPm}`;
                    })()}</td>
                  </tr>
                  <tr style={{ border: 'none'}}>
                  <td style={{fontSize : '17px', border: 'none'}}>Customer Name - </td> <td style={{border: 'none'}}>{customerTransaction.customer_name}</td>
                  <td style={{border: 'none'}}></td><td style={{border: 'none'}}></td>
                  <td style={{border: 'none', textAlign: 'right'}}>Customer Mobile:</td>
                  <td style={{border: 'none', textAlign: 'right'}}>{customerTransaction.customer_mobile}</td>
                  </tr>
                  <tr>
                    
                  </tr>                          
                </tbody>
              </table>
              <hr />
              <table className="table table-sm">
                <thead>
                  <tr>
                    <th style={{ textAlign: 'left' }}>Item Name</th>
                    <th style={{ textAlign: 'right' }}>Qty</th>
                    <th style={{ textAlign: 'right' }}>Price (₹)</th>
                    <th style={{ textAlign: 'right' }}>Amount (₹)</th>
                  </tr>
                </thead>
                <tbody>
                  {clothData
                    .filter(cloth => cloth.qty > 0)
                    .map((cloth, index) => (
                      <tr key={index}>
                        <td style={{ textAlign: 'left' }}>{cloth.cloth_type}</td>
                        <td style={{ textAlign: 'right' }}>{cloth.qty}</td>
                        <td style={{ textAlign: 'right' }}>{cloth.charges_rs}</td>
                        <td style={{ textAlign: 'right' }}>{cloth.amt}</td>
                      </tr>
                    ))}
                  <tr>
                    <td><b>Total Items:</b></td>
                    <td style={{ textAlign: 'right' }}><b>{clothData.reduce((total, cloth) => total + cloth.qty, 0)}</b></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td style={{border: 'none'}}></td>
                    <td style={{border: 'none'}}></td>
                    <td style={{ textAlign: 'right', border: 'none' }}>Total Amount:</td>
                    <td style={{ textAlign: 'right', border: 'none' }}>₹{customerTransaction.dramt}</td>
                  </tr>
                  <tr>
                    <td style={{border: 'none'}}></td>
                    <td style={{border: 'none'}}></td>
                    <td style={{ textAlign: 'right', border: 'none' }}>+ Prev Bal Rs:</td>
                    <td style={{ textAlign: 'right', border: 'none' }}>₹{data.clbal}</td>
                  </tr>
                  <tr>
                    <td style={{border: 'none'}}></td>
                    <td style={{border: 'none'}}></td>
                    <td style={{ textAlign: 'right', border: 'none' }}>- Payment Recd Rs:</td>
                    <td style={{ textAlign: 'right', border: 'none' }}>₹{customerTransaction.cramt}</td>
                  </tr>
                  <tr>
                    <td style={{border: 'none'}}></td>
                    <td style={{border: 'none'}}></td>
                    <td style={{ textAlign: 'right', border: 'none' }}>Outstanding Rs:</td>
                    <td style={{ textAlign: 'right', border: 'none' }}>₹{Number(customerTransaction.dramt + data.clbal) - Number(customerTransaction.cramt)}</td>
                  </tr>
                </tbody>
              </table>
              <table className="table">
                {/* <tr>
                  <td>Total Amount: ₹{customerTransaction.dramt}</td>
                </tr>
                <tr>
                  <td>Advance Rs: ₹{customerTransaction.cramt}</td>
                  <td>Balance Rs: ₹{data.clbal}</td>
                </tr> */}
                <tr><td><hr /></td><td><hr /></td></tr>
                <tr>
                  <td style={{fontSize: '15px'}}>Terms & Conditions</td>
                </tr>
                <tr>
                  <td style={{fontSize: '15px'}}>1. All articles are taken at customer's risk. The firm will not be responsible for
                    any loss or damage and delay pf garments.
                  </td>
                </tr>
                <tr>
                  <td style={{fontSize: '15px'}}>2. All transactions are subject to karad jurisdiction.
                  </td>
                </tr>
                <tr>
                  <td style={{fontSize: '15px'}}>3. We are not responsible for the delay caused by power failure, etc.
                  </td>
                </tr>
                <tr>
                  <td><hr /></td>
                </tr>
                <tr>
                  <td style={{fontSize: '15px', textAlign: 'center'}}><b>* Tueday Closed *</b></td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Bill;
