import React, { useEffect, useState, useRef  } from 'react';
import { useNavigate, generatePath, Link, BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import NavBarMain from './NavBarMain';

function Readytodeliver() {

  const apireq05042024 = process.env.REACT_APP_SERVER_API_URL;
        
  const navigate = useNavigate();
  const [UserMasterDataGlobal, setUserMasterDataGlobal] = useState([]);
  const [lastActivityTime, setLastActivityTime] = useState(Date.now());

  const usernameglobal = JSON.parse(localStorage.getItem('username'));
  const localstorage_id = JSON.parse(localStorage.getItem('localstorage_id'));

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(apireq05042024+`UserMaster/getdatabyusername?username=${usernameglobal}&localstorage_id=${localstorage_id}`);
        if(response.data == "logout") {
          navigatetologin();
        }
        setUserMasterDataGlobal(response.data.UserMasterDataGlobal);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    // Initial fetch if usernameglobal is available
    if (usernameglobal) {
      fetchData();
    }

    // Set up interval to fetch data every 5 minutes
    const intervalId = setInterval(fetchData, 5 * 60 * 1000); // 5 minutes in milliseconds

    // Cleanup function to clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [usernameglobal]); // Dependency array includes usernameglobal

  useEffect(() => {
    if (usernameglobal === null) {
      navigate('/LoginPage');
    } else {
      // Reset last activity time whenever there's user interaction
      const handleUserActivity = () => {
        setLastActivityTime(Date.now());
      };

      window.addEventListener('mousemove', handleUserActivity);
      window.addEventListener('keydown', handleUserActivity);
      window.addEventListener('touchstart', handleUserActivity); // For touch devices

      return () => {
        window.removeEventListener('mousemove', handleUserActivity);
        window.removeEventListener('keydown', handleUserActivity);
        window.removeEventListener('touchstart', handleUserActivity);
      };
    }
  }, [usernameglobal, navigate]);

  useEffect(() => {
    const sessionTimeout = setTimeout(() => {
      const currentTime = Date.now();
      const elapsedTime = currentTime - lastActivityTime;

      if (elapsedTime > 15 * 60 * 1000) { // Log out after 15 minutes of inactivity
        console.log('Logging out due to inactivity...');
        navigatetologin();
      } else {
        // If there's less than 15 minutes left, reset the timeout to avoid unnecessary checks
        sessionTimeout.refresh();
      }
    }, 15 * 60 * 1000); // Check for inactivity every 15 minutes (converted to milliseconds)

    return () => clearTimeout(sessionTimeout);
  }, [lastActivityTime, navigatetologin]);

  // console.log(UserMasterDataGlobal[0].usertype);
  async function navigatetologin() {
    const response = await axios.get(apireq05042024+`UserMaster/logout?username=${usernameglobal}`);
    localStorage.removeItem('username');
    localStorage.removeItem('localstorage_id');
    navigate('/LoginPage');
  }

    const [CustomerMasterData, setCustomerMasterData] = useState([]);
    const MySwal = withReactContent(Swal);

    const [errors, seterrors] = useState({});

    const handleInputChange = (e) => {
      const { name, value } = e.target;
      const updatedFormData = {
        ...formData,
        [name]: value,
      };

      setFormData(updatedFormData);
    };

    const [formData, setFormData] = useState({
      tilldate: '',
      dispatchbuttontext: 'Dispatch it!',
    });

    useEffect(() => {
      // Update the date in the formData when the component mounts
      setFormData((prevData) => ({
        ...prevData,
        tilldate : getCurrentDate(),
      }));
    }, []); 

    const getCurrentDate = () => {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, '0');
      const day = String(today.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    };

  async function viewonclick (event) {
    // function regeneraterecords(event) {
      event.preventDefault();     
      
      Getreadytodeliver();      
  }

  //These variables declared here to show Total of Qty and Amt at the last of table.
  var TotalOutStanding =0;
  var TotalCustomerCount =0;

  async function printContent (event) {
    event.preventDefault();

    Getreadytodeliver();
    
    const printWindow = window.open('', '_blank');

    printWindow.document.write(`
    <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-rbsA2VBKQhggwzxH7pPCaAqO46MgnOM80zW1RWuH61DGLwZJEdK2Kadq2F9CUG65" crossorigin="anonymous">
    <div class="table-container col-md-12 ">
      <h1 style="color: red; font-size: 40px; font-weight: bold; margin-top: 5px; text-align: center;">Outstanding Report</h1>
      
      <table class="table table-bordered mt-3">
        <thead>
          <tr>
            <th style="color: red;">Customer Name</th>
            <th style="color: red;">Outstanding</th>
            <th style="color: red;">Mobile No</th>
          </tr>
          </thead>
          <tbody>
          ${CustomerMasterData.map((c, index) => `
          <tr key=${index}>
            <td>${c.customer_name}</td>
            <td>${Number(c.clbal).toFixed(2)}</td>
            <td>${c.customer_mobile}</td>
          </tr>
        `).join('')}
                
        </tbody>
      </table>
    </div>
  `);
  
    printWindow.document.close();
    printWindow.print();
  };

  const [filteredCustomerData, setFilteredCustomerData] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState('');

  useEffect(() => {
      Getreadytodeliver();
  }, [UserMasterDataGlobal]); 

  async function Getreadytodeliver(toshowcustomermasterdata = null) {
      
    const data = {
      Companyid: UserMasterDataGlobal?.[0]?.Companyid,
      userid: UserMasterDataGlobal?.[0]?._id,
      username: UserMasterDataGlobal?.[0]?.username,
    };

    var response = await axios.post(process.env.REACT_APP_SERVER_API_URL+'CustomerTransactionMaster/Getreadytodeliver', data);
    setCustomerMasterData([]);    
    setFilteredCustomerData([]);   
    if (response.status === 200) {
      if(response.data.length >0) {
        setCustomerMasterData(response.data);        
        setFilteredCustomerData(response.data);  
      }       
    }               
  }


  function handleSearchInputChange(event) {
    setSearchKeyword(event.target.value);
    filterCustomerData(event.target.value);
  }

function filterCustomerData(keyword) {
  const filteredData = CustomerMasterData.filter(customer => {
      return (
          customer.tagno.toLowerCase().includes(keyword.toLowerCase()) ||
          customer.customer_name.toLowerCase().includes(keyword.toLowerCase()) ||
          (customer.customer_mobile && customer.customer_mobile.toString().toLowerCase() == keyword.toLowerCase()) ||
          customer.deliveryon.includes(keyword)
      );
  });
  setFilteredCustomerData(filteredData);
}

  const [dispatchedTagnos, setDispatchedTagnos] = useState([]);
  async function ProcessClothDispatched(event, tagno) {

    var data = {
      tagno: tagno,
      status: "Dispatched",
      Companyid: UserMasterDataGlobal?.[0]?.Companyid,
      userid: UserMasterDataGlobal?.[0]._id,
    };

    const response = await axios.post(process.env.REACT_APP_SERVER_API_URL+'CustomerTransactionMaster/updatetagnodispatched', data); // Correct the URL format

    if(response.status == 200){
      if(response.data.message == "Data saved successfully"){
        setDispatchedTagnos((prevTagnos) => [...prevTagnos, tagno]);
      }
      else if(response.data.message == "Please mark this tag as completed first..."){
        MySwal.fire({
            html: <b>Please mark this tag as completed first...</b>,
            icon: 'warning'
        })    
      }
    }

  }

  function formatJSON(salesTransactions) {
    if (!salesTransactions || salesTransactions.length === 0) {
      return "No sales transactions";
    }
    return salesTransactions.map(transaction => 
      `Cloth - ${transaction.cloth_type}\nQty - ${transaction.qty}\nRate - ${transaction.charges_rs}\nAmt - ${transaction.amt}`
    ).join('\n\n');
  }

  
const rediRedirectToInwardBilling = (event) => {
  event.preventDefault();
  navigate('/InwardAndBillingForm');
};

    return(
           
      <>
      <h1 style={{ color: 'white', backgroundColor: '#4229cb', padding: '5px', cursor: 'default' }} className="text-center"> Ready to Deliver list</h1>
      <NavBarMain />
      <div className="container-fluid" style={{color: '#4229cb', fontWeight: '500' ,backgroundColor: 'rgb(255 255 255)'}} >
      <div className="row mt-2">

        <div className='row offset-8'>
          <div className="col-md-2">
            <button type="submit" className='btn btn-primary' onClick={rediRedirectToInwardBilling}> Back to Inward and Billing</button>
          </div>
          <div className="col-md-2">
            <input
              type="text"
              className='form-control'
              placeholder="Search..."
              value={searchKeyword}
              onChange={handleSearchInputChange}
            />
          </div>
        </div>
        <br /><br />
        <div className="col-md-12 mt-2 table-responsive-xl">
        {/* below table-container code addded to keep table size fixed still its records increases */}
          <div className="table-container" style={{ height: '350px', overflow: 'auto', borderWidth:'0px' }}>
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th style={{backgroundColor:'#726200', color:"white", opacity:'0.8', width: '5%', textAlign: 'right' }}>Tag No</th>
                  <th style={{backgroundColor:'#726200', color:"white", opacity:'0.8', width: '5%', textAlign: 'right'}}>Customer Name</th>
                  <th style={{backgroundColor:'#726200', color:"white", opacity:'0.8', width: '9%', textAlign: 'right'}}>Delivery Date</th>
                  <th style={{backgroundColor:'#726200', color:"white", opacity:'0.8', width: '9%', textAlign: 'right'}}>Mobile No</th>
                  <th style={{backgroundColor:'#726200', color:"white", opacity:'0.8', width: '9%', textAlign: 'right'}}>Status update</th>
                </tr>
              </thead>
              <tbody>

                {filteredCustomerData.map(customer => (
                  <tr key={customer.tagno} data-toggle="tooltip" title={formatJSON(customer.salesTransactions)}>
                      <td style={{textAlign: 'right'}}>{customer.tagno}</td>
                      <td style={{textAlign: 'right'}}>{customer.customer_name}</td>
                      <td style={{textAlign: 'right'}}>
                        {new Date(customer.deliveryon).toLocaleDateString('en-GB')}
                      </td>
                      <td style={{textAlign: 'right'}}>{customer.customer_mobile}</td>
                      {/* <td style={{textAlign: 'right'}}><button onClick={(event) => ProcessClothDispatched(event, customer.tagno)} className="btn btn-info btn-sm" style={{backgroundColor: 'green', color: 'white'}}>{formData.dispatchbuttontext}</button></td> */}
                        <td style={{textAlign: 'right'}}>
                          <button
                            onClick={(event) => ProcessClothDispatched(event, customer.tagno)}
                            className={`btn btn-${dispatchedTagnos.includes(customer.tagno) ? 'success' : 'info'} btn-sm`}
                            style={{ backgroundColor: dispatchedTagnos.includes(customer.tagno) ? '#28a745' : 'green', color: 'white' }}
                            
                          >
                          {dispatchedTagnos.includes(customer.tagno) ? 'Dispatched✅' : formData.dispatchbuttontext}
                          </button>
                        </td>
                  </tr>
                ))}

              </tbody>
            </table>
          </div>        

        </div>
      </div>

    </div>
    </>
    );

}

export default Readytodeliver;